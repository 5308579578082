<template>
  <div>
    <v-card-title> Inscripciones ({{ tableData.length }}) </v-card-title>
    <v-card-text>
      <p v-show="errorTableData" class="text-center">
        <v-icon>mdi-alert-circle</v-icon>
        Error obteniendo los datos
      </p>

      <v-data-table
        v-if="!errorTableData"
        :headers="tableHeaders"
        :items="tableData"
        :loading="loadingTableData"
        loading-text="Cargando..."
      >
        <template v-slot:item.fecha="{ item }">
          {{ new Date(item.fecha).toLocaleDateString() }}
        </template>

        <template v-slot:item.fechaNacimiento="{ item }">
          {{ new Date(item.fechaNacimiento).toLocaleDateString() }}
        </template>

        <template v-slot:item.nombreContacto="{ item }">
          {{ item.nombreContacto }} {{ item.apellidosContacto }}
        </template>

        <template v-slot:item.politicaPrivacidad="{ item }">
          <v-icon v-if="item.politicaPrivacidad">mdi-check-box-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
        </template>

        <template v-slot:item.acciones="{ item }">
          <v-icon class="mr-2" color="indigo" @click="addToClient(item)">
            mdi-account-plus
          </v-icon>

          <v-icon
            v-show="item.correo"
            class="mr-2"
            color="cyan"
            @click="mail(item.correo)"
          >
            mdi-email
          </v-icon>

          <v-icon
            v-show="item.telefono"
            class="mr-2"
            color="purple"
            @click="call(item.telefono)"
          >
            mdi-phone
          </v-icon>

          <v-icon
            v-show="item.telefono"
            class="mr-2"
            color="green"
            @click="whatsapp(item.telefono)"
          >
            mdi-whatsapp
          </v-icon>

          <v-icon color="red" @click="eliminarRow(item._id)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      tableData: [],
      errorTableData: null,
      loadingTableData: true,
      tableHeaders: [
        {
          text: 'Fecha de inscripción',
          value: 'fecha',
        },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Primer apellido', value: 'primerApellido' },
        { text: 'Segundo apellido', value: 'segundoApellido' },
        {
          text: 'Fecha de nacimiento',
          value: 'fechaNacimiento',
        },
        { text: 'Contacto', value: 'nombreContacto' },
        { text: 'Correo', value: 'correo', sortable: false },
        { text: 'Teléfono', value: 'telefono', sortable: false },
        { text: 'Mensaje', value: 'mensaje', sortable: false },
        {
          text: 'Política de privacidad',
          value: 'politicaPrivacidad',
        },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false,
          align: 'center',
        },
      ],
    };
  },
  methods: {
    async getTableData(idActividad) {
      try {
        this.loadingTableData = true;
        const res = await axios.get(
          process.env.VUE_APP_API_URL +
            '/inscripciones?idActividad=' +
            idActividad
        );
        this.tableData = res.data;
        this.loadingTableData = false;
      } catch (error) {
        this.loadingTableData = false;
        this.errorTableData = error.response.data;
      }
    },
    async eliminarRow(id) {
      try {
        this.loadingTableData = true;
        await axios.post(
          process.env.VUE_APP_API_URL + '/inscripciones/eliminar',
          {
            id,
          }
        );
        this.getTableData(this.$route.params.id);
      } catch (error) {
        alert('Error - No se ha podido eliminar');
      }
    },
    async addToClient(inscripcion) {
      try {
        let nuevoCliente = await axios.post(
          process.env.VUE_APP_API_URL + '/clientes/nuevo',
          {
            nombre: inscripcion.nombre,
            primerApellido: inscripcion.primerApellido,
            segundoApellido: inscripcion.segundoApellido,
            fechaNacimiento: inscripcion.fechaNacimiento,
            proteccionDatos: inscripcion.politicaPrivacidad,
          }
        );

        nuevoCliente = nuevoCliente.data;

        await axios.post(process.env.VUE_APP_API_URL + '/contactos/nuevo', {
          idCliente: nuevoCliente._id,
          nombre: `${inscripcion.nombreContacto} ${inscripcion.apellidosContacto}`,
          correo: inscripcion.correo,
          telefono: inscripcion.telefono,
        });

        this.$router.push({
          name: 'Cliente',
          params: { id: nuevoCliente._id },
        });
      } catch (error) {
        alert(error.response.data);
      }
    },
    async mail(correo) {
      location.href = `mailto:${correo}`;
    },
    async call(telefono) {
      location.href = `tel:${telefono}`;
    },
    async whatsapp(telefono) {
      window.open(`https://wa.me/34${telefono}`, '_blank');
    },
  },
  mounted() {
    this.getTableData(this.$route.params.id);
  },
};
</script>

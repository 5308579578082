<template>
  <div>
    <p v-show="errorData" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      {{ errorData }}
    </p>

    <v-card :loading="loadingData" v-if="!errorData">
      <v-card-title>
        <v-img contain max-height="150" src="../../assets/cubo-de-rubik.png">
        </v-img>
      </v-card-title>

      <br />

      <h2 class="text-center">
        {{ data.nombre }}
      </h2>

      <br />

      <v-divider></v-divider>

      <v-card-title>
        Datos de la actividad

        <EditarActividad
          :editedItem="data"
          @edit="getData($route.params.id)"
        ></EditarActividad>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <h3>Fecha:</h3>
            <p>{{ new Date(data.fecha).toLocaleDateString() }}</p>

            <h3>Nombre:</h3>
            <p>{{ data.nombre }}</p>

            <h3>Plazas:</h3>
            <p v-if="data.plazas === 0">Ilimitadas</p>
            <p v-else>{{ data.plazas }}</p>

            <h3>Precio:</h3>
            <p>{{ data.precio }} €</p>

            <h3>Descripción:</h3>
            <p>{{ data.descripcion }}</p>
          </v-col>

          <v-col>
            <h3>Visible:</h3>
            <v-btn icon @click="changeVisible">
              <v-icon v-if="data.visible">mdi-check-box-outline</v-icon>
              <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
            </v-btn>

            <h3>Última modificación:</h3>
            <p>
              {{ new Date(data.ultimaModificacion).toLocaleString() }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <Inscripciones></Inscripciones>

      <v-divider></v-divider>

      <v-card-actions class="mt-4">
        <v-btn color="red" @click="eliminarItem(data._id)">
          <v-icon>mdi-delete</v-icon>
          Eliminar actividad
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import EditarActividad from './EditarActividad';
import Inscripciones from '../Inscripciones/Inscripciones';

export default {
  data() {
    return {
      data: {},
      errorData: null,
      loadingData: true,
    };
  },
  methods: {
    async getData(id) {
      try {
        this.loadingData = true;

        const res = await axios.get(process.env.VUE_APP_API_URL + '/actividades?id=' + id);

        this.data = res.data;

        this.loadingData = false;
      } catch (error) {
        this.loadingData = false;

        this.errorData = error.response.data;
      }
    },
    async changeVisible() {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/actividades/editar', {
          id: this.data._id,
          visible: !this.data.visible,
        });

        this.data.visible = !this.data.visible;
      } catch (error) {
        alert('Error - No se ha podido cambiar la visibilidad');
      }
    },
    async eliminarItem(id) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/actividades/eliminar', {
          id,
        });
        this.$router.push({ name: 'Actividades' });
      } catch (error) {
        alert('Error - No se ha podido eliminar la actividad');
      }
    },
    async eliminarNotificacion(idActividad) {
      axios.post(process.env.VUE_APP_API_URL + '/notificaciones/eliminar', {
        tipo: `nuevaInscripcion-${idActividad}`,
      });
    },
  },
  components: {
    EditarActividad,
    Inscripciones,
  },
  mounted() {
    this.getData(this.$route.params.id);
    this.eliminarNotificacion(this.$route.params.id);
  },
};
</script>
